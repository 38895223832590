.view.employers {
  padding-top: 20px;

  @media (min-width: $tablet-portrait) {
    padding-top: 40px;
  }

  .main-content {
    background: $coop-blue;
    border-radius: 8px;
    padding: 20px;

    .row {
      align-items: center;
    }

    .sub {
      color: rgba($black, 0.8);
      margin: 15px 0 20px;
    }

    p {
      font-size: 16px;
      line-height: 21px;
    }

    h6 {
      color: $coop-red;
    }

    //temp
    h2, .btn-link {
      display: none;
    }
  }

  .recent-posts {
    border: none;
  }
}
