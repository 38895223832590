footer {
  padding: 20px 0;

  .container {
    border-top: 1px solid $grey;
    padding-top: 25px;
  }

  * {
    //color: $white;
  }

  a {
    color: inherit;
    margin-right: 20px;
  }
}
