.modal-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.70);
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 2000000000;
}

.modal {
  position: absolute;
  background: $white;
  overflow: auto;
  border-radius: 6px;
  outline: none;
  padding: 40px 30px;
  display: block;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000000000;

  @media(max-width: $tablet-mid - 1px) {
    max-height: 90%!important;
  }

  @media(min-width: $tablet-mid) {
    padding: 60px 125px 30px;
  }

  &.animated {
    transition: all .5s;
  }

  &.auth {
    max-width: 700px;
    max-height: 400px;
    overflow: auto;
    transition: max-height .3s;

    h2 {
      margin-bottom: 40px;
    }

    .btn-link {
      font-size: 14px;
      line-height: 19px;
      margin-top: 20px;
      color: $black;
    }

    .buttons {
      text-align: center;
      margin-top: 20px;

      @media(min-width: $tablet-portrait) {
        margin-top: 40px;
      }

      .btn {
        display: block;
        margin: 0 auto;
      }
    }

    &.signIn {
      max-height: 550px;

      .forgot-pw {
        height: unset;
        margin: -10px 0 0 auto;
        display: block;
        padding: 0 !important;
      }
    }

    &.register {
      max-height: 675px;
    }
  }

  &.candidate {
    max-width: 700px;
    max-height: 450px;
    overflow: auto;
    transition: max-height, max-width .3s;

    h2 {
      margin-bottom: 30px;
    }

    .buttons {
      margin-top: 40px;
      text-align: center;

      .btn {
        display: block;
        margin: 0 auto;

        &.btn-link {
          font-size: 14px;
          line-height: 19px;
          margin-top: 20px;
          color: $black;
        }
      }
    }

    &.view {
      max-width: 850px;
      max-height: 600px;

      h2 {
        margin: 0;
      }

      .details {
        .detail-info {
          margin-bottom: 10px;
        }

        .btn-outline-primary {
          margin-left: auto;
          display: block;
          padding: 0 30px!important;
        }
      }

      .recommended-by {
        background: $coop-green;
        font-size: 16px;
        line-height: 24px;
        border-radius: 8px;
        text-align: center;
        margin: 10px 0 40px;
        padding: 10px;
      }

      .recommendation {
        margin: 10px 0 0;
        font-size: 16px;
        line-height: 25px;
      }

      .buttons {
        margin: 40px 0;

        .btn {
          width: 100%;
          margin: 10px 0;
        }
      }
    }

    &.accept {
      //max-height: unset;

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.reject {
      max-height: 750px;

      label {
        font-weight: bold;
        margin: 20px 0 10px;
      }
    }
  }

  &.close-position {
    max-width: 700px;
    max-height: 710px;
    overflow: auto;
    transition: max-height, max-width .3s;

    h2 {
      margin-bottom: 30px;
    }

    .row {
      margin-bottom: 5px;
    }

    label {
      margin: 0;

      &.active {
        font-weight: bold;
      }
    }

    .form-control {
      margin: 10px 0;
    }

    .buttons {
      margin: 40px 0;
      text-align: center;

      .btn {
        display: block;
        margin: 0 auto;

        &.btn-link {
          font-size: 14px;
          line-height: 19px;
          margin-top: 20px;
          color: $black;
        }
      }
    }
  }

  &.referral-received, &.no-company {
    max-width: 700px;
    max-height: 540px;
    overflow: auto;
    transition: max-height, max-width .3s;

    h2 {
      margin-bottom: 30px;
    }

    .buttons {
      margin: 40px 0;
      text-align: center;

      .btn {
        display: block;
        margin: 20px auto;
      }
    }
  }

  &.no-company {
    max-height: 320px;
  }
}
