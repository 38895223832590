.view.home {
  .hero {
    padding: 25px 40px;
    background: $coop-blue;
    border-radius: 8px;
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      padding: 50px 125px;
      margin-top: 70px;
    }

    h1 {
      font-size: 50px;
      line-height: 65px;
      font-weight: normal;

      @media(min-width: $tablet-mid) {
        font-size: 100px;
        line-height: 133px;
      }
    }

    .definition {
      font-size: 24px;
      line-height: 29px;

      @media(min-width: $tablet-mid) {
        font-size: 36px;
        line-height: 47px;
      }

      .noun {
        font-style: italic;
        font-weight: bold;
      }
    }

    hr {
      margin: 30px 0 15px;
      border-color: $grey;
    }

    p {
      font-size: 16px;

      @media(min-width: $tablet-mid) {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }

  .info {
    text-align: center;
    max-width: 460px;
    margin: 0 auto;

    h3 {
      margin-bottom: 20px;
    }

    p {
      font-weight: bold;
      color: $dark-grey;
    }
  }

  .job-list {
    border-bottom: 1px solid $grey;

  }

  .how-it-works {
    padding: 50px 0;
    border-bottom: 1px solid $grey;

    .hiw-grid {
      margin: 20px 0 0;

      @media(min-width: $tablet-portrait) {
        margin: 55px 0 0;

        > div {
          border: 1px solid $grey;
          border-top: none;
          border-right: none;

          &:nth-child(3n - 2) {
            border-left: none;
          }

          &:nth-child(n+7) {
            border-bottom: none;
          }
        }
      }
    }
  }

  .to-hire {
    padding: 50px 0 25px;
    border-bottom: 1px solid $grey;
    text-align: center;

    .reasons-list {
      max-width: 90%;
      margin: 0 auto;

      @media(min-width: $tablet-mid) {
        max-width: 75%;
      }

      .reason-card {
        margin-top: 55px;
      }
    }

    .btn {
      margin: 30px auto 0;

    }
  }

  .bottom-hero {
    @extend .hero;
    margin: 30px 0;
    text-align: center;

    p {
      max-width: 460px;
      margin: 8px auto 30px;
      font-weight: bold;
      color: $dark-grey;
    }
  }
}
