.cookie-message {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 15px 0;
  background: $white;
  z-index: 9999999999;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: $tablet-portrait) {
    padding: 20px 0;
  }

  .row {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;

      &.icon {
        display: none;

        @media (min-width: $tablet-portrait) {
          display: flex;
        }
      }
    }
  }

  .logo {
    img {
      max-width: 50px;
      width: 60px;
      max-height: unset;
    }
  }

  p {
    margin-bottom: 5px;

    @media (min-width: $tablet-portrait) {
      margin: 0;
    }

    a {
      text-decoration: underline;
    }
  }
}


.logo {
  display: flex;
  align-items: center;
  font-size: 18px;

  .logo-img {
    max-height: 37px;
    margin-right: 10px;
  }
}

.coop-card {
  padding: $card-padding;
  border: $card-border;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;
  background: $card-background;
}

.job-list {
  padding: 25px 0;
  display: flex;
  flex-direction: column;

  @media(min-width: $tablet-mid) {
    padding: 55px 0 25px;
  }

  .info {
    p {
      margin-bottom: 20px;
      @media(min-width: $tablet-mid) {
        margin-bottom: 60px;
      }
    }
  }

  .sj-list {
    //margin-left: -22.5px;
    //margin-right: -22.5px;

    .col-md-4 {
      padding-left: 22.5px;
      padding-right: 22.5px;
    }

    .not-found {
      text-align: center;
      margin: -40px 0 -10px;

      p {
        margin: 0;
      }
    }
  }

  .btn {
    margin: 10px auto 0;
  }
}

.job-item-link {
 &:hover {text-decoration: none;}
}

.job-item {
  @extend .coop-card;
  height: calc(100% - 20px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  span {
    display: block;
    font-size: 16px;
    line-height: 22px;

    &.sj-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
      flex: 1;
    }

    &.sj-location {
      margin-bottom: 7px;
    }
  }

  .earn-container {
    display: flex;
    justify-content: center;

    .sj-earn {
      display: inline-block;
      margin: 25px auto 0;
      padding: 0 15px;
      background: $coop-green;
      border-radius: 8px;
      height: 25px;
      line-height: 25px;
    }
  }
}

.hiw-card {
  padding: 40px 10px;
  text-align: center;
  position: relative;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: $tablet-portrait) {
    min-height: 175px;
    padding: 50px 10px;
  }

  @media(min-width: $tablet-mid) {
    min-height: 225px;
  }

  img {
    max-width: 100%;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    margin: 0;

    @media(min-width: $tablet-portrait) {
      line-height: 56px;
    }
  }
}

.reason-card {
  @extend .coop-card;
  border-radius: 0;
  padding: 55px 25px 30px;
  text-align: center;
  position: relative;

  @media(min-width: $tablet-mid) {
    padding: 55px 50px 30px;
  }

  .step {
    position: absolute;
    top: -35px;
    left: calc(50% - 36.5px);
    height: 73px;
    width: 73px;
    border-radius: 50%;
    color: $white;
    background: #0A0A0A;
    box-shadow: $card-box-shadow;
    font-size: 64px;
    line-height: 53px;
  }

  h4 {
    font-size: 22px;
    margin-bottom: 15px;

    @media(min-width: $tablet-mid) {
      font-size: 24px;
    }
  }

  p {
    color: $dark-grey;
    margin: 0;
  }

}

.search-wrapper {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    background-image: url("https://client-upload.bytehogs.com/coopful/media/search-icon.svg");
    height: 27px;
    width: 27px;
  }

  .form-control {
    padding-left: 50px;
  }
}

.recommend-candidate-form {
  padding: 20px 0 0;
  text-align: left;
  border-top: 1px solid $grey;

  @media(min-width: $tablet-mid) {
    padding: 60px 0 0;
  }

  h2 {
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 30px;
  }

  input[type="file"] {
    display: none;
  }

  .cv-row {
    margin: 0;

    @media(min-width: $tablet-mid) {
      display: flex;
      margin: 42px auto 0;
      align-items: center;
    }

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 10px 0 0;
    }

    .cv-button {
      min-width: 50%;
      display: block;
      margin: 10px 0;

      @media(min-width: $tablet-mid) {
        margin: 0 0 0 auto;
      }
    }
  }

  .disclaimers {
    margin-bottom: 15px;

    p {
      font-size: 16px;
      line-height: 25px;
      margin:0;
    }
  }

  .submit-button {
    margin: 20px auto 20px;
    display: block;

    @media(min-width: $tablet-mid) {
      margin: 50px auto 0;
    }
  }
}

.disclaimers-container {
  .col-2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.post-job-form {
  text-align: left;

  @media(min-width: $tablet-mid) {
    padding: 45px 0 0;
  }

  h2 {
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 30px;
  }

  label {
    font-weight: bold
  }

  .job-type {
    > div {
      display: flex;
      //align-items: center;
      margin: 15px 0;

      .form-control {
        height: 25px;
        width: 25px;
        margin: 0 15px 0 0;
      }

      &:not(.active) {
        label {
          font-weight: normal;
        }
      }
    }
  }

  .salary-term {
    margin-bottom: 10px;
    @media(min-width: $tablet-mid) {
      margin-top: 32px;
      margin-bottom: 0;
    }
  }

  .recommended-fee {
    height: 25px;
    text-align: right;
    font-size: 16px;
    margin-top: -10px;
  }

  .disclaimers {
    margin-bottom: 15px;

    p {
      font-size: 16px;
      line-height: 25px;
      margin:0!important;
    }
  }

  .submit-button {
    margin: -20px auto 20px;
    display: block;

    @media(min-width: $tablet-mid) {
      margin: 50px auto;
    }
  }
}

.profile-dropdown {
  position: relative;

  &.open {
    .menu {
      max-height: 230px;
      max-width: 195px;
      border: 1px solid $grey;
    }
  }

  .btn-link {
    padding: 0!important;
    font-size: 16px;
  }

  .menu {
    max-height: 0;
    max-width: 0;
    border: 1px solid transparent;
    border-radius: 8px;
    width: 200px;
    position: absolute;
    top: calc(100% + 25px);
    right: 0;
    overflow: hidden;
    background: $white;
    box-shadow: $card-box-shadow;
    z-index: 10;
    transition: all 0.5s ease-in-out, border-radius 0.8s;

    nav {
      padding: 15px;
      text-align: left;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          &.separator {
            border-bottom: 1px solid $grey;
            margin: 7px 0;
          }

          &:not(.separator) {
            margin-bottom: 4px;
          }

          a {
            color: $black;
            font-weight: 400;
          }
        }
      }
    }
  }
}


.pagination {
  justify-content: flex-end;

  li {
    height: 30px;
    width: 30px;
    margin: 0 2px;

    a {
      height: 100%;
      width: 100%;
      display: block;
      text-align: center;
    }

    &:not(.previous):not(.next) {
      border: 1px solid $black;
    }

    &.active {
      background: $black;
      color: $white;
    }

    &.next, &.previous {
      a {
        position: relative;

        &:after {
          content: "";
          display: block;
          height: 18px;
          width: 12px;
          position: absolute;
          top: 6.5px;
          left: 7px;
        }
      }
    }

    &.next {
      a:after {
        background: url("//client-upload.bytehogs.com/coopful/media/pagin-next.svg");
      }

      &.disabled a:after {
        background: url("//client-upload.bytehogs.com/coopful/media/pagin-next-disabled.svg");
      }
    }

    &.previous {
      a:after {
        background: url("//client-upload.bytehogs.com/coopful/media/pagin-prev.svg");
      }

      &.disabled a:after {
        background: url("//client-upload.bytehogs.com/coopful/media/pagin-prev-disabled.svg");
      }
    }
  }
}

.coop-table-head {
  background: $lighter-grey;
  font-size: 16px;
  line-height: 25px;
  padding: 15px 20px;
  display: none;

  @media(min-width: $tablet-mid) {
    display: block;
  }
}

.coop-table-data {
  font-size: 16px;
  line-height: 25px;
  padding: 0 20px;
  margin-bottom: 15px;

  > .row {
    border-bottom: 1px solid $lighter-grey;
    min-height: 56px;

    @media(min-width: $tablet-mid) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .row > div {
      padding-top: 5px;
      padding-bottom: 5px;

      @media(min-width: $tablet-mid) {
        padding: 0 15px;
      }
    }

    .mobile-title {
      background: $lighter-grey;

      @media(min-width: $tablet-mid) {
        display: none;
      }
    }
  }
}

.faqs-section {
  padding: 50px 0 25px;
  border-bottom: 1px solid #AFAFAF;
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }

  .faq-card {
    @extend .coop-card;
    padding: 20px;
    text-align: left;
    margin-bottom: 20px;

    &:last-of-type {
      margin: 0;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      h4 {
        margin: 0;
        font-size: 20px;
      }

      .toggle {
        font-size: 30px;
        line-height: 30px;
        font-weight: bold;
      }
    }

    .faq-content {

      max-height: 0;
      overflow: hidden;
      transition: all .5s;
    }

    &.open {
      .faq-content {
        margin-top: 10px;
        max-height: 100px;
        overflow: hidden;
      }
    }

    p {
      font-size: 17px;
      margin-bottom: 0;
    }
  }
}


.recent-posts {
  padding: 25px 0 25px;
  border-bottom: 1px solid #AFAFAF;
  text-align: center;

  h3 {
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
  }

  .blog-post {
    @extend .coop-card;
    padding: 20px;
    text-align: left;
    margin-bottom: 20px;
    border: none;
    transition: all .5s;
    display: flex;
    flex-direction: column;

    @media(min-width: $tablet-portrait) {
      flex-direction: row;
    }

    &:hover {
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1)
    }

    &:last-of-type {
      margin: 0;
    }

    img {
      margin: -20px;
      margin-bottom: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      @media(min-width: $tablet-portrait) {
        max-width: 45%;
        margin: -20px 20px -20px -20px;
        border-radius: 0;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      @media(min-width: $tablet-mid) {
        max-width: 40%
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      h4 {
        margin: 0;
        font-size: 20px;
      }
    }

    .read-more {
      font-weight: bold;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.savings-calculator {
  padding: 25px 0;
  border-bottom: 1px solid $grey;

  .info {
    text-align: center;
    margin-bottom: 30px;

    h3 {
      margin-bottom: 20px;
    }

    p {
      font-weight: bold;
      color: $dark-grey;
    }
  }

  .salary-input {
    display: flex;
    align-items: center;


    span {
      margin-right: 5px;
      margin-bottom: 20px;
      font-weight: bold;

      @media(min-width: $tablet-mid) {
        margin-bottom: 0;
      }
    }

    input {
      @media(min-width: $tablet-mid) {
        margin: 0;
      }
     }
  }



  .salary-type-btns {
    display: flex;
    justify-content: space-between;

    .btn {
      min-width: unset;
      padding: 0 10px !important;
    }
  }
}

.employer-onboarding {
  overflow: hidden;

  .stage-row {
    display: flex;
    width: 200%;
    transition: margin .5s;

    &.s-1 {
      margin-left: -100%;
    }

    .stage {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .bhg-input-group {
        margin: 0;
      }
    }
  }
}
