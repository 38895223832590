body, html {
  width: 100vw;
  overflow-x: hidden;

  &.ReactModal__Body--open {
    height: 100vh;
    overflow-y: hidden;
  }

  @media print {
    a[href]:after { content: none !important; }
    img[src]:after { content: none !important; }

    @page {
      size: auto;
      margin: 0;
    }
  }
}

*, pre {
  font-family: $base-font;
  color: $black;
}

hr {
  border-color: $grey;
}

.container {
  @media (min-width: $desktop-lg) {
    max-width: 55%;
  }
}

.view, .ReactModalPortal {

  p, pre {
    font-size: 16px;
    line-height: 22px;

    @media(min-width: $tablet-mid) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  pre {
    max-width: 100%;
    text-wrap: normal;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }

  h3, h4, h5, h6 {
    font-weight: bold;
  }

  h2 {
    font-size: 36px;
    line-height: 48px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .container {
    @media (min-width: $desktop-lg) {
      max-width: 55%;
    }
  }

  .form-control {
    height: $input-height;
    line-height: $input-line-height;
    border-radius: $input-border-radius;
    background: $white;
    border: 1px solid $grey;

    &:focus, &:active {
      background: $white;
      border: 1px solid $grey;
    }

    &:disabled {
      background: $lighter-grey;
    }
  }


  textarea {
    resize: none;

    &.form-control {
      height: auto;
      padding: 10px;
      line-height: 1.5;
    }
  }

  input[type="checkbox"] {
    &.form-control {
      height: 20px;
      width: 20px;
    }
  }


  .bhg-input-wrapper {
    .bhg-input-group {
      .input-err-message {
        background: transparent;
        line-height: $input-line-height;
        right: 25px;
        font-weight: normal;
      }
    }
  }

  .form-error {
    color: $error-red;
  }
}

.full-loader {

  .loader-content {
    padding: 50px 0;
    background: $coop-blue;
    border-radius: 8px;
    font-family: $playfair-font;
    text-align: center;
    font-size: 100px;
    line-height: 133px;
    width: 80%;
    margin: 50% auto 0;
    box-shadow: $card-box-shadow;

    @media(min-width: $tablet-mid) {
      max-width: 460px;
      margin: 25% auto 0;
    }
  }
}
