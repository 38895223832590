.marketing-header {
  width: 100%;
  color: $black;
  height: 75px;
  display: flex;
  align-items: flex-end;

  @media print {
    display: none;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    border-bottom: 1px solid $grey;


    @media (min-width: $desktop-xl) {
      //max-width: 1000px;
    }

    .left,
    .header-actions {
      //width: 25%;
      @media (min-width: $tablet-portrait) {
        width: 20%;
      }
    }

    .brand {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
      margin-right: 10px;

      .logo {
        height: 60px;
      }

      a {
        color: inherit;
      }

      .desktop {
        display: none;
        @media (min-width: $tablet-portrait) {
          display: initial;
        }
      }
      .mobile {
        @media (min-width: $tablet-portrait) {
          display: none;
        }
      }
    }

    .main-nav {
      width: 60%;
      text-align: center;
      display: none;
      @media (min-width: $tablet-portrait) {
        display: initial;
      }

      &.hide {
        display: none;
      }

      a {
        padding: 0 10px;
        margin: 0;
        color: inherit;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        @media (min-width: $tablet-mid) {
          margin: 0 15px;
        }
      }
    }

    .header-actions {
      text-align: right;

      .btn {
        min-width: 80px;
        color: $black;

        @media (min-width: $tablet-portrait) {
          min-width: 110px;
        }
      }
    }
  }
}
