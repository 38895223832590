.view {
  &.company-details {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 40px;
    }

    .btn {
      margin: 30px auto 60px;
      display: block;
    }
  }
}
