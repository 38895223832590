.view.blog {
  .hero {
    text-align: center;
    margin-bottom: 20px;
    padding-top: 120px;

    @media (min-width: $tablet-portrait) {
      margin-bottom: 50px;
      padding-top: 155px;
    }

    p {
      margin: 40px auto 0;

      @media (min-width: $tablet-portrait) {
        max-width: 85%;
      }
    }
  }

  .blog-posts {
    a {
      text-decoration: none;
    }

    .blog-post {
      @extend .coop-card;
      padding: 0;
      overflow: hidden;
      margin: 0 0 20px;

      @media (min-width: $tablet-portrait) {
        //margin: 0;
      }

      img {
        width: 100%;
      }

      .content {
        padding: 70px 30px 10px;
        margin-top: -70px;
        color: $white;

        h4 {
          font-weight: bold;
        }

        p {
          color: rgba($white, 0.95)
        }

        > span {
          font-weight: bold;
        }

        .blog-footer {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          font-size: 14px;
        }
      }
    }
  }
}


.view.blog-post {
  .blog-content {
    padding-top: 20px;

    @media (min-width: $tablet-portrait) {
      padding-top: 30px;
    }

    .feature-image {
      max-width: 80%;
      margin: 30px auto;
      display: block;
    }

    .raw-content {

      h3 {
        font-size: 26px;
        line-height: 29px;
      }

      em {
        line-height: 24px;
      }
    }

    hr {
      margin-left: -15px;
      margin-right: -15px;
    }

    .social-shares {
      margin-top: 15px;

      a {
        margin-left: 0;
        margin-right: 1em;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .cta-container {
    text-align: center;
    padding: 40px 0;

    @media (min-width: $tablet-portrait) {
      padding: 70px 0;
    }
  }
}
