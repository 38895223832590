$coop-blue: #ECF7FF;
$coop-green: #ECFFF2;
$coop-red: #D15353;
$coop-light-red: #FFECEC;
$coop-yellow: #FFFDEC;

$playfair-font: 'Playfair Display', serif;

$base-font: $playfair-font;

//BRAND VARIABLES
$brand-primary: $coop-red;
$brand-secondary: #ffffff;
$brand-tertiary: 0;

//COLORS
$black: #000000;
$white: #ffffff;
$lighter-grey: #F2F2F2;
$light-grey: #C4C4C4;
$grey: #AFAFAF;
$dark-grey: #676767;
$error-red: #ff7e7f;

//GLOBAL VARIABLES
$headings-font-family: $playfair-font;
$headings-font-weight: bold;
$headings-color: $black;

$paragraph-font-family: $playfair-font;
$paragraph-font-weight: 400;
$paragraph-font-size: 14px;
$paragraph-line-height: 17px;
$paragraph-color: $black;
$paragraph-margin: 0;

$header-height: 0;
$logo-height: 0;

$button-min-width: unset;
$button-height: 43px;
$button-font-size: 18px;
$button-font-family: $playfair-font;
$button-font-weight: bold;
$button-line-height: $button-height;
$button-border-radius: 10px;
$button-padding: 0 50px;
$button-margin: 0;

$input-height: 47px;
$input-font-size: 16px;
$input-font-family: $playfair-font;
$input-font-weight: 400;
$input-line-height: $input-height;
$input-color: $black;
$input-background-color: rgba(247, 247, 247, 0.94);
$input-border-radius: 8px;
$input-border-color: transparent;
$input-border-width: 0;
$input-padding: 0 10px;
$input-margin: 0 0 20px;
$input-transition: box-shadow 0.55s ease-in-out;
$input-error-bs: 0px 0px 7px 1px rgba($error-red, 0.75);

$label-font-size: 14px;
$label-font-family: $playfair-font;
$label-font-weight: bold;
$label-line-height: 17px;
$label-color: $black;
$label-padding: 0;
$label-margin: 0 0 4px;

$link-color: 0;
$link-font-weight: 0;

$card-padding: 15px 20px 40px;
$card-border: 1px solid $light-grey;
$card-border-radius: 8px;
$card-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
$card-background: $white;

//RESPONSIVE
$mobile-large: 576px;
$tablet-portrait: 768px;
$tablet-mid: 992px;
$tablet-landscape: 1024px;
$desktop: 1200px;
$desktop-lg: 1400px;
$desktop-xl: 1780px;
