.btn {
  border-radius: $button-border-radius;
  border: none;
  height: $button-height;
  line-height: $button-line-height;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  padding: $button-padding!important;
  margin: $button-margin;
  min-width: $button-min-width;

  &:focus {
    outline: none;
  }

  &.btn-rounded {
    border-radius: 25px;
  }

  &.btn-primary {
    background: $brand-primary;
    color: $white;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-primary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-outline-primary {
    background: $white;
    color: $brand-primary;
    border: 1px solid $brand-primary;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      border-color: transparent;
      background: $brand-primary;
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      background: $brand-primary;
      color: $white;
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-secondary {
    background: $brand-secondary;
    color: $brand-primary;
    box-shadow: $card-box-shadow;

    &:not(:disabled):not(.disabled):active {
      background: darken($brand-primary, 20%);
      color: $white;
    }

    &:not(:disabled):not(.disabled):focus {
      box-shadow: $card-box-shadow;
    }
  }

  &.btn-link {
    color: $brand-primary;

    &:not(:disabled):not(.disabled):focus {
      box-shadow: none;
    }
  }

  &.btn-inline {
    padding: 0;
    min-width: 0;
    margin: 0;
    font-size: $paragraph-font-size;
    line-height: $paragraph-line-height;
    display: initial;
  }
}

.google-button {
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto";
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  //display: flex;
  //align-items: center;
  border: none;
  background: $white;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    margin: 0 24px;
    display: block;
  }
}

//Sharing buttons
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff!important;
  margin: 0.5em
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  display: flex;
  align-items: center;
  color: #fff!important;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: unset;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--twitter {
  background-color: #55acee
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9
}

.resp-sharing-button--pinterest {
  background-color: #bd081c
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615
}

.resp-sharing-button--facebook {
  background-color: #3b5998
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373
}

.resp-sharing-button--tumblr {
  background-color: #35465C
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c
}

.resp-sharing-button--reddit {
  background-color: #5f99cf
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1
}

.resp-sharing-button--google {
  background-color: #dd4b39
}

.resp-sharing-button--google:hover {
  background-color: #c23321
}

.resp-sharing-button--linkedin {
  background-color: #0077b5
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293
}

.resp-sharing-button--email {
  background-color: #777
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e
}

.resp-sharing-button--xing {
  background-color: #1a7576
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c
}

.resp-sharing-button--whatsapp {
  background-color: #25D366
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851
}

.resp-sharing-button--hackernews {
  background-color: #FF6600
}
.resp-sharing-button--hackernews:hover, .resp-sharing-button--hackernews:focus {   background-color: #FB6200 }

.resp-sharing-button--vk {
  background-color: #507299
}

.resp-sharing-button--vk:hover {
  background-color: #43648c
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

