.view {
  &.template-1 {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    &.about {
      .main-content {
        h1 {
          margin-bottom: 25px;
        }

        p {
          margin-bottom: 25px;
        }
      }
    }

    .main-content {
      margin: 0 auto;
      max-width: 90%;

      @media(min-width: $tablet-mid) {
        max-width: 75%;
      }

      h1 {
        font-weight: normal;
        margin-bottom: 15px;
      }
    }
  }
}
