.view {
  &.all-jobs {
    text-align: center;
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    .hero {
      //margin-bottom: 60px;

      h1 {
        font-weight: normal;
        margin-bottom: 60px;
      }
    }

    .job-item {
      text-align: left;
    }

    .btn {
      margin: 30px auto 60px;
      text-align: center;
    }
  }

  &.job-details {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    @media print {
      .download-btn, .earn, .recommend {
        display: none;
      }
    }

    .intro {
      padding-bottom: 30px;

      @media(min-width: $tablet-mid) {
        padding-bottom: 60px;
      }

      .name-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h1 {
          flex: 1;
        }

        .download-btn {
          display: none;

          @media(min-width: $tablet-mid) {
            display: initial;
          }
        }
      }

      span {
        font-size: 18px;
        line-height: 24px;
      }

      .location {
        margin-bottom: 10px;
        display: block;
      }

      .earn {
        background: $coop-green;
        border-radius: 8px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        margin-top: 20px;

        &.closed {
          background: $coop-light-red;
        }
      }
    }

    .details {
      h3 {
        margin-bottom: 10px;
      }

      pre {
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 25px;

        @media(min-width: $tablet-mid) {
          margin-bottom: 40px;
        }
      }
    }

    .recommend {
      text-align: center;
      margin-bottom: 60px;

      .open-recommend, .recommend-candidate-form {
        transition: height .5s;
      }

      &.closed {
        .recommend-candidate-form {
          height: 0;
          padding: 0;
          border: none;
          overflow: hidden;
        }
      }

      &.open {
        .open-recommend {
          height: 0;
          overflow: hidden;
        }
      }
    }
  }

  &.post-job-unauth {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 25px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    .btn {
      margin: 50px auto;
      display: block;


    }
  }

  &.post-job {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    h2 {
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 25px;
    }

    a {
      &:hover {
        text-decoration: none;
      }
    }

    /*.btn {
      margin: 50px auto;
      display: block;
    }*/
  }

  &.job-postings {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .positions-list {
      margin: 45px 0 25px;
      max-width: 100%;
      overflow: auto;
      white-space: nowrap;
      padding-bottom: 10px;
      border-bottom: 1px solid #AFAFAF;

      span {
        margin-right: 20px;
        cursor: pointer;

        &.active {
          font-weight: bold;
        }
      }
    }

    .job-top-bar {
      margin: 15px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        //font-weight: normal;
      }

      .job-actions {
        display: flex;

        .btn-link {
          padding: 0 15px!important;
          font-size: 14px;
          border-left: 1px solid $black;
          border-radius: 0;
          height: unset;
          line-height: 14px;

          &:first-child {
            border: none
          }

          &:not(.remove) {
            color: $black;
          }
        }
      }
    }

    .more-details {
      margin: -10px 0 20px;

      > div {
        > span {
          font-weight: 500;
        }
      }
    }

    .actions {
      @media(min-width: $tablet-mid) {
        display: flex;
      }

      .cus-btn {
        @media(max-width: $tablet-mid - 1px) {
          margin: 5px 0;
          display: block;
          width: 100%;
        }
      }
    }

    .closed-positions-table {
      margin-bottom: 90px;
      @media(min-width: $tablet-mid) {
        margin: 0;
      }
    }
  }

  &.submissions {
    margin-top: 40px;

    @media(min-width: $tablet-mid) {
      margin-top: 70px;
    }

    h2 {
      margin-bottom: 20px;
    }

    h4 {
      margin: 20px 0;

      @media(min-width: $tablet-mid) {
        margin: 60px 0 20px;
      }
    }

    .job-position {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .cus-btn {
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    border-radius: 8px;
    min-width: 110px;
    text-align: center;
    margin: 0 4px;
    border: none;
    color: $black;
    text-decoration: none;

    &.view-btn {
      background: $coop-yellow;
    }

    &.accept-btn {
      background: $coop-green;
    }

    &.reject-btn {
      background: $coop-light-red;
    }
  }
}
